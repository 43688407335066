<template>
  <b-modal
    id="update-Role"
    @hidden="$emit('resetModal')"
    title="Update Role"
    hide-footer
  >
    <FormulateForm
      name="RoleAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <div v-for="permission in permissions" :key="permission[0]">
        <div class="roles-modal">
          <FormulateInput
            name="permissions"
            :options="permission[1]"
            v-model="assignedPermissions"
            type="checkbox"
            :label="permission[0].toUpperCase()"
          />
        </div>
      </div>

      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span> Updating... </span>
        </span>
        <span v-else>
          <span> Update </span>
          Role</span
        >
      </FormulateInput>
    </FormulateForm>

  </b-modal>
</template>

<script>
import { roles } from "@/config/api/roles";
export default {
  props: ["data"],
  data() {
    return {
      values: {},
      performAction: false,
      permissions: [],
      assignedPermissions: [],
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      this.updateRole(data);
    },
    selectPemission(data) {
      return data.map((item) => {
        return {
          value: item._id,
          label: item.name.toUpperCase(),
        };
      });
    },

    updateRole(data) {
      this.performAction = true;
      const api = roles.update;
      api.id=this.data.selectedRole._id
      api.data = data;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Role updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Role is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    data(val) {
      if (val) {
        const permissionData = val.permissions;
        let categories = [];
        let perms = {};
        for (let i = 0; i < permissionData.length; i++) {
          if (!categories.find((item) => item == permissionData[i].category)) {
            categories.push(permissionData[i].category);
            perms[permissionData[i].category] = [permissionData[i]];
          } else {
            perms[permissionData[i].category].push(permissionData[i]);
          }
        }

        this.permissions = Object.keys(perms)
          .map((key) => [key, perms[key]])
          .map((item) => {
            return [item[0], this.selectPemission(item[1])];
          });
        this.values.name = val.selectedRole.name;
        this.values.permissions = val.selectedRole.permissions.map(
          (item) => item._id
        );
        this.assignedPermissions = this.values.permissions
      }
    },
  },
};
</script>

<style>
.roles-modal {
  background-color: #fafafa;
  margin-bottom: 12px;
  padding: 10px 18px;
  border-radius: 12px;
}
.roles-modal .formulate-input-group-item .formulate-input-label {
  line-height: 4px;
  margin-bottom: 6px;
}
.roles-modal .formulate-input-group {
  display: flex;
  flex-wrap: wrap;
}
.roles-modal .formulate-input-group-item {
  margin: 2px 8px;
}

.roles-modal
  .formulate-input[data-classification="box"]
  .formulate-input-element-decorator {
  width: 1.2em;
  height: 1.2em;
}
</style>