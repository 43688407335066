<template>
  <b-modal
    id="action-Role"
    @hidden="$emit('resetModal')"
    title="Add Role"
    hide-footer
  >
    <FormulateForm
      name="RoleAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <div v-for="permission in permissions" :key="permission[0]">
        <div class="roles-modal">
          <FormulateInput
            name="permissions"
            :options="permission[1]"
            type="checkbox"
            :label="permission[0].toUpperCase()"
          />
        </div>
      </div>

      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span> Adding... </span>
        </span>
        <span v-else>
          <span> Add </span>
          Role</span
        >
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { roles } from "@/config/api/roles";
export default {
  props: ["permissionData"],
  data() {
    return {
      values: {},
      performAction: false,
      permissions: [],
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      this.addRole(data);
    },
    selectPemission(data) {
      return data.map((item) => {
        return {
          value: item._id,
          label: item.name.toUpperCase(),
        };
      });
    },
    addRole(data) {
      // this.performAction = true;
      const api = roles.create;

      api.data = data;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$formulate.reset("RoleAction");
          this.$bvToast.toast("Role added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Role is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    permissionData(val) {
      let categories = [];
      let perms = {};
      for (let i = 0; i < val.length; i++) {
        if (!categories.find((item) => item == val[i].category)) {
          categories.push(val[i].category);
          perms[val[i].category] = [val[i]];
        } else {
          perms[val[i].category].push(val[i]);
        }
      }

      this.permissions = Object.keys(perms)
        .map((key) => [key, perms[key]])
        .map((item) => {
          return [item[0], this.selectPemission(item[1])];
        });
    },
  },
};
</script>

<style>
.roles-modal {
  background-color: #fafafa;
  margin-bottom: 12px;
  padding: 10px 18px;
  border-radius: 12px;
}
.roles-modal .formulate-input-group-item .formulate-input-label {
  line-height: 4px;
  margin-bottom: 6px;
}
.roles-modal .formulate-input-group {
  display: flex;
  flex-wrap: wrap;
}
.roles-modal .formulate-input-group-item {
  margin: 2px 8px;
}

.roles-modal
  .formulate-input[data-classification="box"]
  .formulate-input-element-decorator {
  width: 1.2em;
  height: 1.2em;
}
</style>